import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { keyBy } from 'lodash';
import { NzMessageService } from 'ng-zorro-antd/message';
import { catchError, combineLatest, EMPTY, expand, filter, interval, map, NEVER, of, pairwise, reduce, startWith, switchMap } from 'rxjs';
import { Document, DocumentClass, DocumentState } from 'src/app/graphql/frontend-data-graphql';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DocumentDataSource } from 'src/app/shared/services/data-sources/document-data-source';

import { DocumentApiActions, DocumentUIActions } from './document.actions';
import { selectActiveDocument, selectDocumentClassesForActiveDocument } from './document.reducer';
import { DocumentService } from './document.service';
import { getVerificationType } from './util/verification.util';
import { DocumentService as LegacyDocumentService } from '../../shared/services/document.service';
import { DocumentClassService } from '../document-class/document-class.service';

@Injectable()
export class DocumentEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);

  private documentSrv = inject(DocumentService);
  private authSrv = inject(AuthService);
  private documentClassSrv = inject(DocumentClassService);
  private translateSrv = inject(TranslateService);
  private messageSrv = inject(NzMessageService);

  private legacyDocumentService = inject(LegacyDocumentService);

  select$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentUIActions.select),
      filter(({ id }) => id != null),
      concatLatestFrom(() => this.store.select(selectActiveDocument)),
      filter(([action, activeDocument]) => action.id !== activeDocument?.id), // skip if already active
      switchMap(([{ id }]) => this.documentSrv.findOne(id!)),
      switchMap(documentRes => {
        if (documentRes.errors) {
          throw documentRes.errors;
        } else {
          const { document } = documentRes.data;
          return combineLatest([of(document), this.documentClassSrv.findMany({ parentId: { eq: document.documentClass?.identifier } })]); // TODO: make request smaller
        }
      }),
      map(([document, documentClassesRes]) => {
        if (documentClassesRes.errors) {
          throw documentClassesRes.errors;
        } else {
          // NOTE: This redefines the index_in_pdf meaning to the "local" document and not the global document
          (<Document>document).pages.forEach((p, i) => (p.index_in_pdf = i));

          const documentClasses = documentClassesRes.data.documentClasses.edges.map(edge => edge.node);
          return DocumentApiActions.findOneSucceeded({
            item: document as Document,
            documentClasses: documentClasses as DocumentClass[],
            verificationType: getVerificationType(document as Document, documentClasses as DocumentClass[])
          });
        }
      }),
      catchError(err => of(DocumentApiActions.requestFailed({ errors: [err] })))
    );
  });

  findParents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentApiActions.findOneSucceeded),
      switchMap(({ item }) =>
        of(item).pipe(
          expand(d =>
            (d?.parent_document_id ? this.documentSrv.findOne(d?.parent_document_id, { basic: true }) : EMPTY).pipe(
              map(res => <Document>res.data?.document)
            )
          ),
          // Accumulate documents into an array.
          reduce((acc: Document[], document: Document) => [...acc, document], [])
        )
      ),
      map(list => list.reverse()),
      map(parents => DocumentApiActions.findParentsSucceeded({ documentId: parents.at(-1)!.id, parents })),
      catchError(err => of(DocumentApiActions.requestFailed({ errors: [err] })))
    );
  });

  reload$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentUIActions.reload),
      concatLatestFrom(() => this.store.select(selectActiveDocument)),
      filter(([action, activeDocument]) => action.id === activeDocument?.id), // skip if already active
      switchMap(([{ id }]) => this.documentSrv.findOne(id!)),
      concatLatestFrom(() => this.store.select(selectDocumentClassesForActiveDocument)),
      switchMap(([documentRes, documentClasses]) => {
        if (documentRes.errors) {
          throw documentRes.errors;
        } else {
          const { document } = documentRes.data;
          return of(
            DocumentApiActions.findOneSucceeded({
              item: document as Document,
              documentClasses,
              verificationType: getVerificationType(document as Document, documentClasses as DocumentClass[])
            })
          );
        }
      }),
      catchError(err => of(DocumentApiActions.requestFailed({ errors: [err] })))
    );
  });

  updateAvailable$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentApiActions.findOneSucceeded),
      switchMap(({ item }) => {
        const source = new DocumentDataSource({
          filter: of({
            id: { eq: item?.id }
          }),
          sorting: of([]),
          documentService: this.legacyDocumentService,
          destroy: NEVER,
          skipLatestUpdate: true
        });
        return source.data$.pipe(
          startWith([item]),
          pairwise(),
          filter(([a, b]) => a.length > 0 && b.length > 0 && a[0].state != b[0].state),
          map(([a, b]) => b)
        );
      }),
      map(updatedDocuments => updatedDocuments[0] as Document),
      map(update => DocumentApiActions.updatedOne({ item: update }))
    );
  });

  relockAtInterval$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentApiActions.findOneSucceeded),
      switchMap(({ item }) =>
        // repeat any minute, starting now.
        interval(60 * 1000).pipe(
          startWith(-1),
          map(() => item)
        )
      ),
      filter(
        item =>
          [DocumentState.AwaitingVerification, DocumentState.AwaitingExportVerification].includes(item.state) &&
          !this.documentSrv.isBlockedByOthers(item) &&
          !this.documentSrv.isJustBlockedByMe(item)
      ),
      switchMap(item => {
        return this.documentSrv.lockOne(item.id).pipe(
          map(({ data, errors }) =>
            data?.lockOneDocument
              ? DocumentApiActions.lockOneSucceeded({ lockedBy: this.authSrv.user!.email, lockedUntil: new Date(Date.now() + 90 * 1000) })
              : DocumentApiActions.requestFailed({ errors: errors ?? [] })
          ),
          catchError(err => {
            this.messageSrv.error(this.translateSrv.instant('DOCUMENT.cannotLock'));
            return of(DocumentApiActions.requestFailed({ errors: [err] }));
          })
        );
      })
    );
  });

  initExtraction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentApiActions.findOneSucceeded),
      filter(({ verificationType }) => verificationType === 'extraction'),
      switchMap(({ item }) => {
        return this.documentClassSrv
          .findMany(
            { identifier: { like: item.documentClass?.identifier }, deletedAt: { is: null } },
            { pageSize: 1000, childrenFilter: { deletedAt: { is: null } } }
          )
          .pipe(
            map(res => res.data?.documentClasses.edges.map(dt => dt.node as DocumentClass) ?? []),
            map(classes => {
              return keyBy(classes, dc => dc.identifier);
            })
          );
      }),
      map(documentClasses => DocumentUIActions.initExtractionVerification({ documentClasses }))
    );
  });
}
